export enum Role {
  Account_Management = 'Account_Management',
  Administrators = 'Administrators',
  API_AppPos = 'API_AppPos',
  API_RefreshToken = 'API_RefreshToken',
  API_MO = 'API_MO',
  API_Omni = 'API_Omni',
  BankMDR_Approve = 'BankMDR_Approve',
  BankMDR_Create = 'BankMDR_Create',
  BankMDR_Export = 'BankMDR_Export',
  BankMDR_Import = 'BankMDR_Import',
  BankMDR_Remove = 'BankMDR_Remove',
  BankMDR_Update = 'BankMDR_Update',
  BankMDR_View = 'BankMDR_View',
  BankMDR_CalculateFee = 'BankMDR_CalculateFee',
  DB_DashboardMonitor = 'DB_DashboardMonitor',
  DB_DashboardMonitorExport = 'DB_DashboardMonitorExport',
  DevelopmentUnit_Add = 'DevelopmentUnit_Add',
  DevelopmentUnit_ConfigPolicyRouting = 'DevelopmentUnit_ConfigPolicyRouting',
  DevelopmentUnit_Export = 'DevelopmentUnit_Export',
  ER_EReceiptExport = 'ER_EReceiptExport',
  ER_EReceiptResend = 'ER_EReceiptResend',
  ER_EReceiptView = 'ER_EReceiptView',
  Installment_Create = 'Installment_Create',
  Installment_Export = 'Installment_Export',
  Installment_Process = 'Installment_Process',
  Installment_View = 'Installment_View',
  InstallmentBank_View = 'InstallmentBank_View',
  InstallmentBank_Add = 'InstallmentBank_Add',
  InstallmentBank_Export = 'InstallmentBank_Export',
  InstallmentBank_Process = 'InstallmentBank_Process',
  InstallmentCardBIN_Add = 'InstallmentCardBIN_Add',
  InstallmentCardBIN_Export = 'InstallmentCardBIN_Export',
  InstallmentCardBIN_Process = 'InstallmentCardBIN_Process',
  InstallmentCardBIN_View = 'InstallmentCardBIN_View',
  InstallmentBankTenor_Add = 'InstallmentBankTenor_Add',
  InstallmentBankTenor_Export = 'InstallmentBankTenor_Export',
  InstallmentBankTenor_Process = 'InstallmentBankTenor_Process',
  InstallmentBankTenor_View = 'InstallmentBankTenor_View',
  InstallmentFeeBank_Add = 'InstallmentFeeBank_Add',
  InstallmentFeeBank_Process = 'InstallmentFeeBank_Process',
  InstallmentFeeBank_Export = 'InstallmentFeeBank_Export',
  InstallmentFeeBank_View = 'InstallmentFeeBank_View',
  Installment_SendToSPOS = 'Installment_SendToSPOS',
  MerchantMDR_Approve = 'MerchantMDR_Approve',
  MerchantMDR_CalculateFee = 'MerchantMDR_CalculateFee',
  MerchantMDR_Create = 'MerchantMDR_Create',
  MerchantMDR_Export = 'MerchantMDR_Export',
  MerchantMDR_Import = 'MerchantMDR_Import',
  MerchantMDR_Remove = 'MerchantMDR_Remove',
  MerchantMDR_Update = 'MerchantMDR_Update',
  MerchantMDR_View = 'MerchantMDR_View',
  MO_Installment_MerchantFee_View = 'MO_Installment_MerchantFee_View',
  MO_Installment_MerchantFee_Export = 'MO_Installment_MerchantFee_Export',
  MO_Installment_MerchantFeeDetails_Export = 'MO_Installment_MerchantFeeDetails_Export',
  MO_MDRMerchant_Export = 'MO_MDRMerchant_Export',
  MO_MDRMerchant_View = 'MO_MDRMerchant_View',
  MO_MDRMerchantDetails_Export = 'MO_MDRMerchantDetails_Export',
  MM_MerchantExport = 'MM_MerchantExport',
  MM_MerchantExport_Reconciliation = 'MM_MerchantExport_Reconciliation',
  MM_MerchantExport_Business = 'MM_MerchantExport_Business',
  MM_MerchantOnboarding = 'MM_MerchantOnboarding',
  MM_MerchantUpdate = 'MM_MerchantUpdate',
  MM_MerchantView = 'MM_MerchantView',
  MP_AutoSettlementConfigApprove = 'MP_AutoSettlementConfigApprove',
  MP_AutoSettlementConfigCreate = 'MP_AutoSettlementConfigCreate',
  MP_AutoSettlementConfigExport = 'MP_AutoSettlementConfigExport',
  MP_AutoSettlementConfigRemove = 'MP_AutoSettlementConfigRemove',
  MP_AutoSettlementConfigUpdate = 'MP_AutoSettlementConfigUpdate',
  MP_AutoSettlementConfigView = 'MP_AutoSettlementConfigView',
  MP_SettlementManual = 'MP_SettlementManual',
  MP_SettlementManualExport = 'MP_SettlementManualExport',
  MP_SettlementManualView = 'MP_SettlementManualView',
  MP_TransDifferenceApprove = 'MP_TransDifferenceApprove',
  MP_TransDifferenceUpload = 'MP_TransDifferenceUpload',
  MP_TransDifferenceExport = 'MP_TransDifferenceExport',
  MP_TransDifferenceView = 'MP_TransDifferenceView',
  POS_Export = 'POS_Export',
  POS_view = 'POS_view',
  Reconciliation_Detail_Export = 'Reconciliation_Detail_Export',
  Reconciliation_Export = 'Reconciliation_Export',
  Reconciliation_UpdateStatus = 'Reconciliation_UpdateStatus',
  Reconciliation_View = 'Reconciliation_View',
  RF_RefundCreate = 'RF_RefundCreate',
  RF_RefundExport = 'RF_RefundExport',
  RF_RefundProcess = 'RF_RefundProcess',
  RF_RefundView = 'RF_RefundView',
  RP_ReportMerchant = 'RP_ReportMerchant',
  RP_ReportSale = 'RP_ReportSale',
  RP_TransactionInformation_View = 'RP_TransactionInformation_View',
  RP_TransactionInformation_Export = 'RP_TransactionInformation_Export',
  RP_SuccessfulTransactions_View = 'RP_SuccessfulTransactions_View',
  RP_SuccessfulTransactions_Export = 'RP_SuccessfulTransactions_Export',
  RP_FailTransactionsByBank_View = 'RP_FailTransactionsByBank_View',
  RP_FailTransactionsByBank_Export = 'RP_FailTransactionsByBank_Export',
  RP_DebtFail_View = 'RP_DebtFail_View',
  RP_DebtFail_Export = 'RP_DebtFail_Export',
  RP_SettlementTransactions_View = 'RP_SettlementTransactions_View',
  RP_SettlementTransactions_Export = 'RP_SettlementTransactions_Export',
  RP_SettlementTransactionSummary_View = 'RP_SettlementTransactionSummary_View',
  RP_SettlementTransactionSummary_Export = 'RP_SettlementTransactionSummary_Export',
  RP_RefundInformation_View = 'RP_RefundInformation_View',
  RP_RefundInformation_Export = 'RP_RefundInformation_Export',
  RP_RefundInitialization_View = 'RP_RefundInitialization_View',
  RP_RefundInitialization_Export = 'RP_RefundInitialization_Export',
  RP_RefundBank_View = 'RP_RefundBank_View',
  RP_RefundBank_Export = 'RP_RefundBank_Export',
  RP_RefundDecline_View = 'RP_RefundDecline_View',
  RP_RefundDecline_Export = 'RP_RefundDecline_Export',
  RP_TerminalInformation_View = 'RP_TerminalInformation_View',
  RP_TerminalInformation_Export = 'RP_TerminalInformation_Export',
  RP_POSInformation_View = 'RP_POSInformation_View',
  RP_POSInformation_Export = 'RP_POSInformation_Export',
  RP_BranchInformation_View = 'RP_BranchInformation_View',
  RP_BranchInformation_Export = 'RP_BranchInformation_Export',
  RP_MerchantInformation_View = 'RP_MerchantInformation_View',
  RP_MerchantInformation_Export = 'RP_MerchantInformation_Export',
  RP_AdjustmentMDRMerchant_View = 'RP_AdjustmentMDRMerchant_View',
  RP_AdjustmentMDRMerchant_Export = 'RP_AdjustmentMDRMerchant_Export',
  RP_MDRMerchantNotDeclared_View = 'RP_MDRMerchantNotDeclared_View',
  RP_MDRMerchantNotDeclared_Export = 'RP_MDRMerchantNotDeclared_Export',
  SYS_BankInfo = 'SYS_BankInfo',
  SYS_BankInfo_Export = 'SYS_BankInfo_Export',
  SYS_CardBIN = 'SYS_CardBIN',
  SYS_CardBIN_Export = 'SYS_CardBIN_Export',
  SYS_DeviceForceLogon = 'SYS_DeviceForceLogon',
  SYS_LogAction = 'SYS_LogAction',
  SYS_LogLogin = 'SYS_LogLogin',
  SYS_LogUpdateData = 'SYS_LogUpdateData',
  SYS_MerchantCategoryCode = 'SYS_MerchantCategoryCode',
  SYS_MerchantCategoryCode_Export = 'SYS_MerchantCategoryCode_Export',
  SYS_MerchantUser = 'SYS_MerchantUser',
  SYS_StaffUser = 'SYS_StaffUser',
  Terminal_Approve = 'Terminal_Approve',
  Terminal_Import = 'Terminal_Import',
  TM_TerminalCreate = 'TM_TerminalCreate',
  TM_TerminalExport = 'TM_TerminalExport',
  TM_TerminalRequest = 'TM_TerminalRequest',
  TM_TerminalUpdateProfile = 'TM_TerminalUpdateProfile',
  TM_TerminalUpdateStatus = 'TM_TerminalUpdateStatus',
  TM_TerminalView = 'TM_TerminalView',
  UM_Administrator = 'UM_Administrator',
  UM_BranchView = 'UM_BranchView',
  UM_BranchExport = 'UM_BranchExport',
  UM_ResetPassword = 'UM_ResetPassword',
  UM_UserCreate = 'UM_UserCreate',
  UM_UserExport = 'UM_UserExport',
  UM_UserUpdateBranch = 'UM_UserUpdateBranch',
  UM_UserUpdatePassword = 'UM_UserUpdatePassword',
  UM_UserUpdateProfile = 'UM_UserUpdateProfile',
  UM_UserUpdateRole = 'UM_UserUpdateRole',
  UM_UserUpdateStatus = 'UM_UserUpdateStatus',
  UM_UserView = 'UM_UserView'
}
